import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const RoundedWhiteBox = React.forwardRef((
  { children, hasManyOptions, sx, ...props },
  ref,
) => (
  <Box
    ref={ref}
    overflow="auto !important"
    sx={(_theme) => ({
      bgcolor: 'white',
      borderRadius: 6,
      borderTopLeftRadius: hasManyOptions ? 0 : 'none',
      px: 2,
      py: 1,
      [_theme.breakpoints.down('md')]: {
        px: 1,
        borderRadius: 0,
        height: '100%',
        borderTopLeftRadius: 0,
      },
      ...sx,
    })}
    {...props}
  >
    {children }
  </Box>
));

export default RoundedWhiteBox;

RoundedWhiteBox.propTypes = {
  children: PropTypes.node.isRequired,
  hasManyOptions: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

RoundedWhiteBox.defaultProps = {
  hasManyOptions: false,
  sx: {},
};
