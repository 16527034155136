import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import PropTypes from 'prop-types';
import LoadingIconButton from './LoadingIconButton';

const LoadingIconButtonTooltip = ({ tooltipTitle, ...props }) => (
  <Tooltip title={tooltipTitle}>
    <span>
      <LoadingIconButton {...props} />
    </span>
  </Tooltip>
);

LoadingIconButtonTooltip.propTypes = {
  tooltipTitle: PropTypes.node.isRequired,
};

export default LoadingIconButtonTooltip;
