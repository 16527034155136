import React, { useState, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useBooleanState } from '../../hooks';
import { BUSINESS_PROFILE_DETAILS } from '../../graphql';
import { FingoDialog } from '../dialogs';
import BusinessProfileHeader from './businessProfileHeader';
import { formatGraphQlDate } from '../../helpers';
import DicomSocieties from '../dicom/DicomSocieties';
import DicomShareholders from '../dicom/DicomShareholders';
import BusinessProfileVehicle from './BusinessProfileVehicle';
import JudicialDetail from './judicialDetail';
import BusinessProfileTaxAppraisal from './BusinessProfileTaxAppraisal';

const BusinessProfileComponent = ({ masterEntityId, label, disabled }) => {
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedTab, setSelectedTab] = useState('societies');
  const [open, setOpen] = useBooleanState();

  const [loadBusinessProfile, { data, loading, error }] = useLazyQuery(
    BUSINESS_PROFILE_DETAILS,
    {
      variables: { masterEntityId },
      fetchPolicy: 'network-only',
      onCompleted: ({ getMasterEntity }) => {
        const ProfilesList = getMasterEntity.businessProfile;
        setSelectedProfileId(ProfilesList[ProfilesList.length - 1].id);
        setOpen(true);
      },
    },
  );

  const selectedProfile = useMemo(
    () => data?.getMasterEntity.businessProfile.find(
      (dicom) => dicom.id === selectedProfileId,
    ),
    [selectedProfileId],
  );

  const availableOptions = data?.getMasterEntity.businessProfile
    .map((profile) => ({
      timestamp: moment(profile.createdAt),
      value: profile.id,
      key: `profile-${profile.id}`,
      label: formatGraphQlDate(moment(profile.createdAt)),
    }))
    .sort((a, b) => a.timestamp.diff(b.timestamp));

  const TableComponent = useMemo(() => {
    if (selectedTab === 'shareholders') return DicomShareholders;
    if (selectedTab === 'vehicles') return BusinessProfileVehicle;
    if (selectedTab === 'judicialDetails') return JudicialDetail;
    if (selectedTab === 'taxAppraisal') return BusinessProfileTaxAppraisal;
    return DicomSocieties;
  }, [selectedTab, selectedProfileId]);

  return (
    <>
      <LoadingButton
        onClick={loadBusinessProfile}
        loading={loading}
        variant="contained"
        disabled={error || disabled}
        size="small"
      >
        {error ? 'Error ' : ''}
        {label}
      </LoadingButton>
      <FingoDialog
        title={`Perfil Comercial de ${data?.getMasterEntity.name}`}
        open={open}
        handleClose={setOpen}
        maxWidth="lg"
        fullWidth
      >
        <BusinessProfileHeader
          availableOptions={availableOptions}
          selectedProfile={selectedProfile}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedProfileId={selectedProfileId}
          setSelectedProfileId={setSelectedProfileId}
        />
        <TableComponent dicomModel={selectedProfile} />
      </FingoDialog>
    </>
  );
};

BusinessProfileComponent.propTypes = {
  label: PropTypes.string,
  masterEntityId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

BusinessProfileComponent.defaultProps = {
  disabled: false,
  label: 'Detalle',
};

export default BusinessProfileComponent;
