import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import FingoDataGrid from '../dataGrids/FingoDataGrid';
import { BusinessProfileTaxAppraisalColumns } from '../../constants';

const BusinessProfileTaxAppraisal = ({ dicomModel }) => (
  <Stack
    direction="column"
    sx={{ marginTop: 5 }}
  >
    <FingoDataGrid
      rows={dicomModel?.taxAppraisal ?? []}
      columns={BusinessProfileTaxAppraisalColumns}
      serverFilters={false}
      hideFooter
      sx={{ minHeight: 250 }}
    />
  </Stack>
);

BusinessProfileTaxAppraisal.propTypes = {
  dicomModel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    taxAppraisal: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      year: PropTypes.string.isRequired,
      appraisal: PropTypes.string.isRequired,
      quality: PropTypes.string.isRequired,
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      chasisNumber: PropTypes.string.isRequired,
      motorNumber: PropTypes.string.isRequired,
      licensePlace: PropTypes.string.isRequired,
      ownerName: PropTypes.string.isRequired,
      ownerLastName: PropTypes.string.isRequired,
      ownerOrganizati: PropTypes.string.isRequired,
      ownerRut: PropTypes.string.isRequired,
      ownerDv: PropTypes.string.isRequired,
      ranking: PropTypes.string.isRequired,
      carType: PropTypes.string.isRequired,
      use: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

export default BusinessProfileTaxAppraisal;
