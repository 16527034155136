import React, { useCallback } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';

const MenuButton = ({
  options,
  selectedOption,
  setSelectedOption,
  placeholder,
  minWidth,
  id,
}) => {
  const handleSelection = useCallback((event) => {
    setSelectedOption(event.target.value);
  }, []);
  return (
    <Box sx={{ minWidth }} id={id}>
      <FormControl fullWidth>
        <InputLabel>{placeholder}</InputLabel>
        <Select
          id="menu-button"
          value={selectedOption || ''}
          onChange={handleSelection}
          label={placeholder}
        >
          {options.map(({ id: opId, label }) => (
            <MenuItem value={opId} key={opId}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

MenuButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
};

MenuButton.defaultProps = {
  selectedOption: '',
  minWidth: 120,
  id: null,
};

export default MenuButton;
